:root {
  --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Source Sans Pro", sans-serif;
  --nav-font: "Poppins", sans-serif;
}

:root {
  --background-color: #ffffff;
  --default-color: #1a1f24;
  --heading-color: #485664;
  --accent-color: #854ce6;
  --surface-color: #ffffff;
  --contrast-color: #ffffff;
}

.light-background {
  --background-color: #eef0f2;
  --surface-color: #ffffff;
  --border-color: rgba(0, 0, 0, 0.1);
  --accent-color: #854ce6;

  --nav-background-color: #ffffff;

  --nav-color: #485664;
  --nav-mobile-background-color: #ffffff;
  --nav-dropdown-background-color: #ffffff;
  --nav-dropdown-color: #485664;
}

.dark-background {
  --background-color: #090917;
  --border-color: rgba(9, 9, 23, 0.8);
  --nav-background-color: #090917;
  --accent-color: #854ce6;

  --default-color: #f2f3f4;
  --heading-color: #f2f3f4;
  /* --surface-color: #11181d; */
  --surface-color: #111928;

  --contrast-color: #f2f3f4;
  /* --surface-color: #4f6171; */

  --nav-color: #485664;

  --nav-mobile-background-color: #090917;
  --nav-dropdown-background-color: #ffffff;
  --nav-dropdown-color: #485664;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}
.main-container {
  min-height: 100vh;
  height: 100%;
  background-color: var(--surface-color);
  /* background-color: rgb(39, 39, 42); */
}

a {
  color: var(--default-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
  Headers
--------------------------------------------------------------*/

.nav-main-container {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--nav-background-color);
  color: var(--default-color);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.nav-container {
  width: 100%;
  /* max-width: 1200px; */
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}
.nav-logo-container {
  display: flex;
  align-items: center;
  width: 80%;
  padding: 0 6px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  justify-content: center;
  /* color: inherit; */
}
@media screen and (max-width: 768px) {
  .nav-logo-container {
    display: none;
  }
}

.nav-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  list-style: none;

  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.nav-item-text {
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

section,
.section {
  padding: 60px 0;
  scroll-margin-top: 100px;
  overflow: clip;
}

@media (max-width: 1199px) {
  section,
  .section {
    scroll-margin-top: 66px;
  }
}
/*--------------------------------------------------------------
  Section Header
--------------------------------------------------------------*/

.section-header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media (max-width: 960px) {
    flex-direction: column;
  }
}
.section-header-title {
  font-size: 52px;
  font-weight: 600;
  color: var(--text-primary-color);
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
}
.section-header-description {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 15px;
  color: var(--text-secondary-color);
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

/*--------------------------------------------------------------
  Blog
--------------------------------------------------------------*/
.blog {
  min-height: 85vh;
  height: 100%;
}
.blog article {
  background-color: var(--surface-color);
  box-shadow: 0 4px 16px var(--border-color);
  padding: 30px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.blog .title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .title a {
  color: var(--heading-color);
  transition: 0.3s;
}

.blog .title a:hover {
  color: var(--accent-color);
}

.read-more {
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--default-color);
  padding-bottom: 10px;
  border-bottom: 2px solid var(--accent-color);
  cursor: pointer;
}

.time-to-read {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 0;
}

.blog-pagination {
  padding-top: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog-pagination li a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-pagination li a.active,
.blog-pagination li a:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.blog-pagination li a.active a,
.blog-pagination li a:hover a {
  color: var(--contrast-color);
}

/* details */

.blog-details {
  padding-bottom: 30px;
}

.blog-details .post-img {
  overflow: hidden;
}

.blog-details .title {
  color: var(--heading-color);
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 30px 0;
}

.blog-details .content {
  margin-top: 20px;
  color: var(--default-color);
}

.blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog-details .content blockquote {
  --bs-border-opacity: 0;
  /* border-color: rgba(var(--accent-color), var(--bs-border-opacity)) !important; */
  border-color: var(--accent-color) !important;
}

.blog-details .content blockquote p {
  color: var(--default-color);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog-details .meta-top {
  margin-top: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog-details .meta-top ul li + li {
  padding-left: 20px;
}

.blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .meta-bottom i {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  display: inline;
}

.blog-details .meta-bottom a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  transition: 0.3s;
}

.blog-details .meta-bottom a:hover {
  color: var(--accent-color);
}

.blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog-details .meta-bottom .tags li + li::before {
  padding-right: 6px;
  color: var(--default-color);
  content: ",";
}

.blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog-details .meta-bottom .share i {
  padding-left: 5px;
}

.highlight.js-code-highlight {
  padding: 1.5rem;
  background-color: #08090a;
  color: white;
  margin-bottom: 10px;
}

.content .highlight .highlight__panel.js-actions-panel {
  display: none;
}
.content .highlight.plaintext {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
  font-size: 0.875em;
}

.tag-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
  margin-bottom: 10px;
}
.tag-content {
  font-size: 12px;
  font-weight: 400;
  color: var(--accent-color);
  background-color: var(--surface-color);
  padding: 5px 10px;
  border-radius: 10px;
  border: 1.5px solid var(--accent-color);
}

.dark-background .section-wrapper {
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
}
.section-wrapper {
  background: linear-gradient(
      38.73deg,
      rgba(253, 251, 251, 0.15) 40%,
      rgba(235, 237, 238, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(253, 251, 251, 0.8) 50%,
      rgba(235, 237, 238, 1) 100%
    );
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
}
